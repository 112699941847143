import React, { useContext, useEffect, useRef, useState } from 'react';
import { PricingToggleContext } from '../../../contexts/pricingToggleContext';
import Badge from '../../../molecules/Badge/Badge';
import { Switch } from '../../../molecules/Switch/Switch.styles';
import { colors } from '../../../styles/atoms/colors';
import { getSpanishText } from '../../../utils/temporalSpanishManagement';
import {
  RateHeading,
  SwitchWrapper,
  ToggleSection,
  Wrapper,
} from '../styles/PricingToggle.styled';

const PricingToggle = ({ savingsTag, slug }) => {
  const [stuck, setStuck] = useState(false);
  const { isAnnualPlan, setIsAnnualPlan } = useContext(PricingToggleContext);
  const ref = useRef();

  useEffect(() => {
    const handler = () => {
      const toggleBottom = ref.current.getBoundingClientRect().bottom;
      const cardDeckTop = ref.current.nextSibling.getBoundingClientRect().top;

      toggleBottom !== cardDeckTop ? setStuck(true) : setStuck(false);
    };

    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  return (
    <ToggleSection isStuck={stuck} stuckTop={75} ref={ref} id="price-toggle">
      <Wrapper>
        <SwitchWrapper>
          <RateHeading active={!isAnnualPlan}>
            {getSpanishText(slug).PRICING.MONTHLY_PLANS}
          </RateHeading>
          <Switch
            checked={isAnnualPlan}
            onClick={() => setIsAnnualPlan(prevState => !prevState)}
          />
          <RateHeading active={isAnnualPlan}>
            {getSpanishText(slug).PRICING.ANNUAL_SAVINGS}
          </RateHeading>
        </SwitchWrapper>
        {savingsTag && (
          <Badge
            size="lg"
            badgeColor={isAnnualPlan ? colors.yellow[500] : colors.gray[100]}
            textColor={colors.gray[900]}
          >
            {savingsTag}
          </Badge>
        )}
      </Wrapper>
    </ToggleSection>
  );
};

export default PricingToggle;
