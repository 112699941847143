import React from 'react';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import Section from '../../../molecules/Section/Section';
import PricingAddOnCard from '../../Cards/PricingAddOnCard';
import PricingCard from '../../Cards/PricingCard';
import PricingToggle from '../components/PricingToggle';
import {
  Callout,
  PricingAddOnsWrapper,
  CardWrapper,
  customInnerStyle,
  customSectionStyle,
  Wrapper,
} from '../styles/PricingCardDeck.styled';

const PricingCardDeck = ({
  component: { addOns, callout, cards, cta, id, savingsTagCopy },
  ...rest
}) => {
  return (
    <>
      <PricingToggle savingsTag={savingsTagCopy} {...rest} />
      <Section id={id} style={customSectionStyle} innerStyle={customInnerStyle}>
        <Wrapper>
          <CardWrapper>
            {cards &&
              cards.map((card, ind) => (
                <PricingCard
                  key={card?.id}
                  active={card?.active}
                  cardTitle={card?.cardTitle}
                  annualPrice={card?.annualPrice}
                  monthlyPrice={card?.monthlyPrice}
                  cta={card?.cta}
                  listTitle={card?.includesText}
                  listContent={card?.listContent}
                  features={card?.features}
                  index={ind}
                  {...rest}
                />
              ))}
          </CardWrapper>
          {callout && <Callout dangerouslySetInnerHTML={{ __html: callout }} />}
          {addOns?.length && (
            <PricingAddOnsWrapper>
              {addOns.map(addOn => (
                <PricingAddOnCard component={addOn} key={addOn.id} {...rest} />
              ))}
            </PricingAddOnsWrapper>
          )}
          {cta ? (
            <div className="cta-container">
              <CallToAction
                variant={cta.variant}
                size={cta.size}
                value={cta.label}
                link={cta.url}
                icon={cta.icon}
                reverse={cta.reverse}
              />
            </div>
          ) : (
            ''
          )}
        </Wrapper>
      </Section>
    </>
  );
};

export default PricingCardDeck;
